<template>
  <div class="relative">
    <div aria-hidden="true" class="absolute inset-0 flex items-center">
      <div class="w-full border-t border-gray-200"></div>
    </div>
    <div class="relative flex justify-center text-sm font-medium leading-6">
      <span class="bg-white px-6 text-gray-900">Or continue with</span>
    </div>
  </div>

  <div class="mt-6 grid grid-cols-2 xl:grid-cols-4 gap-4">
    <actions-button reverse-content size="sm" variant="white" @click="socialLoginRedirect('facebook')" dusk="social-login-facebook">
      <icon name="logos:facebook" size="16"/>
    </actions-button>

    <actions-button reverse-content size="sm" variant="white" @click="socialLoginRedirect('google')" dusk="social-login-google">
      <icon name="logos:google-icon" size="16"/>
    </actions-button>

    <actions-button reverse-content size="sm" variant="white" @click="socialLoginRedirect('twitter')" dusk="social-login-twitter">
      <icon name="logos:twitter" size="16"/>
    </actions-button>

    <actions-button reverse-content size="sm" variant="white" @click="socialLoginRedirect('linkedin-openid')" dusk="social-login-linkedin">
      <icon name="logos:linkedin-icon" size="16"/>
    </actions-button>
  </div>
</template>

<script setup>
function socialLoginRedirect(provider) {
  window.location.href = `${useRuntimeConfig().public.api_url}/social/login/${provider}`
}
</script>